import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Img from "gatsby-image"
import Table from "react-bootstrap/Table";
const ProductItem = (props) => (
	<Row>
		<Col xs={12} sm={8}>
			<h2>{props.heading}</h2>
			<p>{props.data}</p>
			<Table >
				<tbody>
					<tr>
						<td>Packaging Available:</td>
						<td>{props.package}</td>
					</tr>
					<tr>
						<td>Color:</td>
						<td>{props.color}</td>
					</tr>
					<tr>
						<td>Coverage:</td>
						<td>{props.coverage}</td>
					</tr>
					<tr>
						<td>Drying time:</td>
						<td>{props.dryingTime}</td>
					</tr>
				</tbody>
			</Table>
		</Col>
		<Col xs={12} sm={4} md={{ span: 3, offset: 1 }}>
			<Img fluid={props.image}></Img>
		</Col>
	</Row>
)

export default ProductItem;