import React from "react";
import Layout from "../../components/layout";
import ProductItem from "../../components/productItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { graphql } from 'gatsby';
const synteticEnamels = ({data}) => (
    <Layout>
        <Row>
            <Col>
            <h1>Synthetic Enamels</h1></Col>
        </Row>
        {/* signal red */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY SIGNAL RED"} data={"Interior emulsion having a slight sheen finish, durable, washable, water–based modified acrylic emulsion paint for interior walls. It provides a smooth finish over the interior primer. It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"signal red"} coverage={"8-10 meter square/ltr"} dryingTime={"surface dry:10-15 minutes."} ></ProductItem>

        <hr />
        {/* golden yellow */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY GOLDEN YELLOW"} data={"As a finishing coat for exterior & interior surface, modified alkyd & pigment based systems is used over metal alloys etc."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"Golden Yellow"} coverage={"8-10 meter square/ltr"} dryingTime={"surface dry:10-15 minutes."} ></ProductItem>

        <hr />
        {/* oxford blue */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY OXFORD BLUE"} data={"As a finishing coat for exterior & interior surface, modified alkyd & pigment based systems is used over metal alloys etc."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"Oxford Blue."} coverage={"8-10 meter square/ltr"} dryingTime={"surface dry:10-15 minutes, hard dry:12-16hrs."} ></ProductItem>

        <hr />
        {/* bus green */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY BUS GREEN"} data={"I2k Epoxy zincchromate( stroncium chromate) yellow primer acts an anti corrossive paint. Provides superior corrosive resistance & very good binding properties.It is used as primer."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr"} color={"Yellow"} coverage={"9-10 meter square/ltr."} dryingTime={"surface dry:10-15 minutes, hard dry:12-16hrs."} ></ProductItem>

        <hr />
        {/* sky blue */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY SKY BLUE"} data={"Wood primer is used for exterior &interior surface, fast drying,& binder nitro cellulose based systems is used as wood coatings & over plywood’s to level the pores to give good finish."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20 ltr, 4ltr, 1ltr."} color={"Yellow"} coverage={"9-10 meter square/ltr."} dryingTime={"Surface dry:5-10 minutes, hard dry:2-3 hrs."} ></ProductItem>

        <hr />

        {/* glossy black */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY BLACK"} data={"Exterior wall primer is a water-based wall coating suitable for application on exteriors as an undercoat to exterior emulsions. It gives an excellent opacity and whiteness . Improves appearance of top coat . It can be used on all types of exterior cement plasters, exterior ceilings, asbestos sheets, concrete and existing painted surfaces."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr."} color={"White."} coverage={"120-130 sq ft/ltr."} dryingTime={"surface dry time 20 min."} ></ProductItem>

        {/* traffic blue */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY TRAFFIC BLUE"} data={"Interior wall primer is a water-based wall coating suitable for application interiors as an undercoat to interior emulsions.it gives an excellent opacity and whiteness . Improves appearance of top coat . It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr"} color={"White"} coverage={"120-130 sq ft/ltr."} dryingTime={"Surface dry time 20 min"} ></ProductItem>

        <hr />{/* emerald green */}
        <ProductItem heading={"SYNTHETIC ENAMEL EMERALD GREEN"} data={"Interior wall primer is a water-based wall coating suitable for application interiors as an undercoat to interior emulsions.it gives an excellent opacity and whiteness . Improves appearance of top coat . It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20 ltr, 4ltr, 1ltr."} color={"Yellow"} coverage={"10-12 meter square/ltr"} dryingTime={"Surface dry:10-15 minutes,hard dry:2-3 hrs."} ></ProductItem>

        <hr />{/* french blue */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY FRENCH BLUE"} data={"It provides superior corrosive resistance & very good binding properties.It is used as primer."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20 ltr, 4ltr, 1ltr."} color={"Grey"} coverage={"10-12 meter square/ltr"} dryingTime={"surface dry: 10-15 minutes, hard dry:8-10 hrs"} ></ProductItem>

        <hr />{/* azure blue */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY AZURE BLUE"} data={"It provides superior corrosive resistance & very good binding properties.It is used as primer."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20 ltr, 4ltr, 1ltr."} color={"Grey"} coverage={"10-12 meter square/ltr"} dryingTime={"Surface dry: 10-15 minutes, hard dry:8-10 hrs."} ></ProductItem>

        <hr />{/* post office red */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY POST OFFICE RED"} data={"It provides superior corrosive resistance & very good binding properties.It is used as primer."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20 ltr, 4ltr, 1ltr"} color={"Grey"} coverage={"10-12 meter square/ltr"} dryingTime={"Surface dry: 10-15 minutes, hard dry:8-10 hrs"} ></ProductItem>
        {/* HERE */}
        <hr />{/* india saffron */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY INDIA SAFFRON"} data={"As a finishing coat for exterior & interior surface, modified alkyd & pigment based systems is used over metal alloys etc."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"India Saffron"} coverage={"10-12 meter square/ltr."} dryingTime={"surface dry:10-15 minutes."} ></ProductItem>

        <hr />{/* orange red  */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY BRIGHT ORANGE RED"} data={"As a finishing coat for exterior & interior surface, modified alkyd & pigment based systems is used over metal alloys etc."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"Bright Orange Red"} coverage={"10-12 meter square/ltr"} dryingTime={"surface dry:10-15 minutes."} ></ProductItem>

        <hr />{/* glossy white  */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY WHITE"} data={"As a finishing coat for exterior & interior surface, modified alkyd & pigment based systems is used over metal alloys etc."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"White"} coverage={"10-12 meter square/ltr"} dryingTime={"surface dry:10-15 minutes."} ></ProductItem>

        <hr />{/* apple  green */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY APPLE GREEN"} data={"As a finishing coat for exterior & interior surface, modified alkyd & pigment based systems is used over metal alloys etc."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"Apple Green"} coverage={"10-12 meter square/ltr"} dryingTime={"surface dry:10-15 minutes."} ></ProductItem>

        <hr />{/* pu primer */}
        <ProductItem heading={"PU PRIMER"} data={"As a finishing coat for exterior & interior surface, modified alkyd & pigment based systems is used over metal alloys etc."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"Smoke Grey"} coverage={"10-12 meter square/ltr"} dryingTime={"surface dry:10-15 minutes."} ></ProductItem>

        <hr />{/* smoke grey */}
        <ProductItem heading={"SYNTHETIC ENAMEL GLOSSY SMOKE GREY"} data={"Interior emulsion having a slight sheen finish, durable, washable, water–based modified acrylic emulsion paint for interior walls. It provides a smooth finish over the interior primer. It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"Dark Admilarity Grey"} coverage={"10-12 meter square/ltr"} dryingTime={"surface dry:10-15 minutes."} ></ProductItem>

        <hr />{/* enamel  silver */}
        <ProductItem heading={"SYNTHETIC ENAMEL SILVER"} data={"As a finishing coat for exterior & interior surface, modified alkyd & pigment based systems is used over metal alloys etc."} image={data.syntheticEnamelsMain.childImageSharp.fluid} package={"20ltr, 4ltr, 1ltr, 1/2 ltr"} color={"Silver"} coverage={"10-12 meter square/ltr"} dryingTime={"surface dry:10-15 minutes."} ></ProductItem>

        

       

    </Layout>
)

export default synteticEnamels;

export const query = graphql`
query {
    

    syntheticEnamelsMain: file(relativePath: { eq: "syntheticEnamelsMain.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

     
}`